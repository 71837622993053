import { DEFAULT_LANGUAGE } from "~/i18n";

export type SeoProps = {
  title: string;
  description: string;
  seo_keywords?: string;
  og_title?: string;
  og_description?: string;
  og_image?: string;
  og_type?: string;
  twitter_card?: string;
  twitter_title?: string;
  twitter_description?: string;
  twitter_image?: string;
  author?: string;
  robots?: string;
  googleBot?: string;
  canonical_url?: string;
  image?: string;
  url: URL | string;
  lang?: string;
};

export const seoGenerator = ({ url, ...seo }: SeoProps) => {
  const canonical = url
    ?.toString()
    ?.split("?")?.[0]
    ?.replace("http://", "https://");

  return [
    { title: seo.title || "" },
    { tagName: "link", rel: "canonical", href: canonical },
    { name: "title", content: seo?.title || "" },
    { name: "description", content: seo?.description?.slice(0, 160) || "" },
    {
      name: "keywords",
      content:
        seo?.seo_keywords ||
        "Hudhud, Hudhud shop, Hudhud shop online, Hudhud shop online shopping, Hudhud shop online store, Hudhud shop online fashion, Hudhud shop online fashion store, Hudhud shop online fashion shopping, Hudhud shop online fashion shopping store, Hudhud shop online fashion shopping store in Egypt, Hudhud shop online fashion shopping store in Cairo, Hudhud shop online fashion shopping store in Alexandria, Hudhud shop online fashion shopping store in Giza, Hudhud shop online fashion shopping store in Mansoura, Hudhud shop online fashion shopping store in Tanta, Hudhud shop online fashion shopping store in Hurghada, Hudhud shop online fashion shopping store in Sharm El Sheikh, Hudhud shop online fashion shopping store in ",
      // content: seo?.seo_keywords || "hudhud, هدهد, hudhud shop",
    },
    { property: "og:title", content: seo?.og_title || "هدهد Hudhud" },
    {
      property: "og:description",
      content: seo?.og_description || seo?.description || "",
    },
    { property: "og:image", content: seo?.og_image || seo?.image || "" },

    {
      property: "twitter:title",
      content: seo?.twitter_title || seo?.title || "",
    },
    {
      property: "twitter:description",
      content: seo?.twitter_description || seo?.description || "",
    },
    {
      property: "twitter:image",
      content: seo?.twitter_image || seo?.image || "",
    },
    { property: "og:type", content: seo?.og_type || "product" },
    { property: "twitter:card", content: "summary_large_image" },
    { charset: "UTF-8" },
    { name: "viewport", content: "width=device-width, initial-scale=1" },
    { name: "author", content: seo?.author || "هدهد Hudhud" },
    {
      name: "robots",
      content:
        seo?.robots ||
        "index, follow, max-snippet:-1, max-video-preview:-1, max-image-preview:large",
    },
    { name: "googlebot", content: seo?.googleBot || "Allow" },

    { property: "og:locale", content: seo?.lang || DEFAULT_LANGUAGE },
  ];
};
